.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.app_background {
  background-color: blue;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}


.nibling_photo {
  height: 500px;
  width: 40%;
  padding: 3%;
  background-color: lightgray;
}

.homepage_card {
  height: 350px;
  width: 200px;
  margin: 3%;
}

.paragraph {
  margin: 5%;
  padding: 5%;
  background-color: lightgray;
  border-radius: 5%;
  box-shadow: 10px 10px black;
}

.profile-photo {
  height: 300px;
  width: 300px;
  padding: 3%;
  margin: 3%;
  background-color: lightgray;
}

.github {
  margin: 2%;
  font-size: 200%;
}

.my_tracker {
  background-color: lightgray;
  margin: 3%;
  padding: 5%;
}

.busy_quizzy {
  background-color: lightgray;
  margin: 3%;
  padding: 5%;
}

.coders_platform {
  background-color: lightgray;
  margin: 3%;
  padding: 5%;
}

.contact_background {
  background-color: gray;
  padding: 2%;
  margin-left: 20%;
  margin-right: 20%;
}

.contact_form {
  background-color: lightgray;
  padding: 10%;
}

.header_title {
  font-family: monospace;
  font-size: 300%;
  text-shadow: 3px 3px rgb(123, 131, 131);
}

.max_width {
  width: 100%;
}

.stick_trick_vid {
  width: 300px;
  height: 200px;
  margin: 5%;
}

.message_box {
  width: 90%;
}

.homepage_title {
  background-color: peru;
  color: rgb(0, 0, 0);
  box-shadow: 10px 10px lightgrey;
  padding: 3%;
  border-radius: 5%;
}

.resume_image {
  width: 400px;
  height: 600px;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.sidebar {
  position: fixed;
  top: 0;
  left: -125px;
  width: 125px;
  height: 100%;
  background-color: peru;
  transition: left 300ms ease-out;
  z-index: 1;
}

.sidebar.active {
  left: 0;
}

.sidebar ul {
  margin: 0;
  padding: 0;
}

.sidebar li {
  list-style: none;
}

.sidebar li a {
  font-size: 18px;
  text-decoration: none;
  padding: 10px;
  display: block;
  color: #fff;
}

.hamburger {
  border: none;
  outline: 0;
  width: 50px;
  position: absolute;
  right: -50px;
  background-color: peru;
}

.hamburger:after,
.hamburger:before,
.hamburger div {
  background-color: #fff;
  height: 5px;
  margin: 7px 0;
  border-radius: 3px;
  content: "";
  display: block;
  transition: all 300ms ease-in-out;
}

.sidebar.active .hamburger:before {
  transform: translateY(12px) rotate(135deg);
}

.sidebar.active .hamburger::after {
  transform: translateY(-12px) rotate(-135deg);
}

.sidebar.active .hamburger div {
  transform: scale(0);
}